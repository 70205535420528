import React, { useEffect, useState, useMemo } from 'react'
import { FormGroup, Input, Row, Col, Label, Button, Spinner } from 'reactstrap'
import { Briefcase, Save, Search } from 'react-feather'
import 'bs-stepper/dist/css/bs-stepper.min.css'
import '@/styles/core/base/plugins/forms/form-wizard.scss'
import { useApi } from '@/services/api'
import './companies-form.scss'
import ComunaSelector from '@/components/ComunaSelector'
import useCompaniesStore from '@/stores/companies-store'
import classNames from 'classnames'

export default function CompaniesForm({ uuid, onCompanyLoaded, onSave, viewOnly }) {
	const [loading, setLoading] = useState(true)
	const [company, setCompany] = useState({})
	const [validation, setValidation] = useState({})
	const [searchLoading, setSearchLoading] = useState(false)
	const [searchDone, setSearchDone] = useState(false)

	const isNew = useMemo(() => {
		return !uuid
	}, [uuid])

	useEffect(() => {
		if (!isNew) {
			setSearchDone(true)
			useApi({
				url: `/companies/${uuid}`,
				setLoading,
				success: ({ data }) => {
					useCompaniesStore.getState().updateCompany(data)
					setCompany(data)
					onCompanyLoaded?.(data)
				},
			})
		} else {
			setCompany({
				name: '',
				rut: '',
				comuna_id: '',
				address: '',
				giro: '',
			})
			onCompanyLoaded?.(null)
			setLoading(false)
		}
	}, [uuid, isNew])

	const [saveLoading, setSaveLoading] = useState(false)
	const saveCompany = useApi(() => ({
		method: isNew ? 'POST' : 'PUT',
		url: `/companies${isNew ? '' : `/${uuid}`}`,
		data: { ...company },
		setLoading: setSaveLoading,
		setValidation,
		successMessage: `Listo! Tu empresa ha sido ${isNew ? 'creada' : 'actualizada'}`,
		success: ({ data }) => {
			useCompaniesStore.getState().updateCompany(data)
			onSave?.(data, isNew)
		},
	}))

	const searchPublicData = useApi(() => ({
		url: `/sii-pub`,
		method: 'POST',
		data: { rut: company.rut },
		setLoading: setSearchLoading,
		setValidation,
		success: ({ data }) => {
			setCompany({
				...company,
				name: data.name,
				address: data.address,
				comuna_id: data.comuna_id,
				giro: data.giro,
			})
			setSearchDone(true)
		},
	}))

	if (loading) {
		return (
			<div className="d-flex justify-content-center py-4">
				<Spinner />
			</div>
		)
	}

	return (
		<div
			className={classNames(`companies-form px-3`, {
				'companies-form--viewonly': viewOnly && !isNew,
			})}
		>
			<div className="horizontal-wizard ">
				<div className="bs-stepper linear">
					<div className="bs-stepper-header">
						<div className="step active">
							<button type="button" className="step-trigger">
								<span className="bs-stepper-box">
									<Briefcase size={18} />
								</span>
								<span className="bs-stepper-label">
									<span className="bs-stepper-title">Detalles de la Empresa</span>
									<span className="bs-stepper-subtitle">Ingresa todos los datos de tu empresa</span>
								</span>
							</button>
						</div>
					</div>
				</div>
				<div className="bs-stepper-content py-2 px-3" style={{ background: 'white', paddingTop: '5px' }}>
					<Row>
						<FormGroup tag={Col} md="6">
							<Label>RUT de la Empresa</Label>
							<Input
								type="text"
								placeholder="12345678-5"
								value={company.rut}
								onChange={(e) => {
									setCompany({ ...company, rut: e.target.value })
								}}
								onKeyPress={(e) => {
									if (e.key === 'Enter') searchPublicData()
								}}
								className={validation.rut ? 'is-invalid' : ''}
								disabled={!!company.issuer_id}
							/>
							{!!validation.rut && <div className="form-error-message">{validation.rut}</div>}
						</FormGroup>
						{isNew && (
							<FormGroup tag={Col} md="6">
								<Label>&nbsp;</Label>
								<div>
									<Button
										color="primary"
										disabled={searchLoading}
										onClick={() => searchPublicData()}
									>
										{searchLoading ? (
											<Spinner className="mr-2" size="sm" />
										) : (
											<Search className="mr-2" size={14} />
										)}
										Buscar RUT
									</Button>
								</div>
							</FormGroup>
						)}
					</Row>
					{searchDone && (
						<Row>
							<FormGroup tag={Col} md="12">
								<Label>Razón Social</Label>
								<Input
									type="text"
									placeholder="Mi Empresa SPA"
									value={company.name}
									onChange={(e) => {
										setCompany({ ...company, name: e.target.value })
									}}
									className={validation.name ? 'is-invalid' : ''}
								/>
								{!!validation.name && <div className="form-error-message">{validation.name}</div>}
							</FormGroup>
							<FormGroup tag={Col} md="4">
								<Label>Comuna</Label>
								<ComunaSelector
									type="text"
									value={company.comuna_id}
									onChange={(e) => {
										setCompany({ ...company, comuna_id: e.target.value })
									}}
									className={validation.comuna_id ? 'is-invalid' : ''}
								/>
								{!!validation.comuna_id && (
									<div className="form-error-message">{validation.comuna_id}</div>
								)}
							</FormGroup>
							<FormGroup tag={Col} md="8">
								<Label>Dirección</Label>
								<Input
									type="text"
									value={company.address}
									onChange={(e) => {
										setCompany({ ...company, address: e.target.value })
									}}
									className={validation.address ? 'is-invalid' : ''}
								/>
								{!!validation.address && (
									<div className="form-error-message">{validation.address}</div>
								)}
							</FormGroup>
							<FormGroup tag={Col} md="12">
								<Label>Giro del Negocio</Label>
								<Input
									type="text"
									placeholder="Servicios digitales"
									value={company.giro}
									onChange={(e) => {
										setCompany({ ...company, giro: e.target.value })
									}}
									className={validation.giro ? 'is-invalid' : ''}
								/>
								{!!validation.giro && <div className="form-error-message">{validation.giro}</div>}
							</FormGroup>
						</Row>
					)}
				</div>
			</div>

			{(!viewOnly || isNew) && searchDone && (
				<Button
					block
					className="mt-2 mb-3"
					color="primary"
					disabled={saveLoading}
					onClick={() => saveCompany()}
				>
					{saveLoading ? (
						<Spinner />
					) : (
						<>
							<Save className="mr-2"></Save>
							{isNew ? 'Registrar Empresa' : 'Actualizar Empresa'}
						</>
					)}
				</Button>
			)}
		</div>
	)
}

