import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { useApi } from '@/services/api'

let comunasCache = window.localStorage.getItem('comunas')
if (comunasCache) comunasCache = JSON.parse(comunasCache)

export default function ComunaSelector(props) {
	const [comunas, setComunas] = useState([])
	useEffect(() => {
		if (comunasCache) {
			setComunas(comunasCache)
			return
		}

		useApi({
			url: '/comunas',
			setData: (data) => {
				data.sort((a, b) => a.city.localeCompare(b.city) || a.name.localeCompare(b.name))
				comunasCache = data
				window.localStorage.setItem('comunas', JSON.stringify(comunasCache))
				setComunas(comunasCache)
			},
		})
	}, [])

	return (
		<Input {...props} type="select" className={`comuna-selector ${props.className || ''}`}>
			<option value="">Seleccionar...</option>
			{comunas.map((x) => (
				<option key={`comuna-${x.id}`} value={x.id}>
					{x.name} / {x.city}
				</option>
			))}
		</Input>
	)
}

