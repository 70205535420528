import useAuthStore from '@/stores/auth-store'
import useCompaniesStore from '@/stores/companies-store'
import usePermissionsStore, { Permissions } from '@/stores/permissions-store'
import { lazy } from 'react'

const shouldBeLoggedIn = () => {
	const isLoggedIn = useAuthStore.getState().isLoggedIn()
	if (!isLoggedIn) {
		window.sessionStorage.setItem('login_redirect', window.location.pathname)
		return `/login`
	}
}

const shouldHaveActiveCompany = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (!activeCompany) return '/companies'
}

const userShould = (permission) => () => usePermissionsStore.getState().userShould(permission)

const shouldSaleStatusBeNotDisabled = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (activeCompany?.issuer_sale_status == 'disabled') return '/companies'
}

const shouldPurchaseStatusBeNotDisabled = () => {
	const activeCompany = useCompaniesStore.getState().activeCompany
	if (activeCompany?.issuer_purchase_status == 'disabled') return '/companies'
}

export default [
	{
		path: '/dashboard',
		component: lazy(() => import('@/views/Dashboard')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
	},
	{
		path: '/documents',
		component: lazy(() => import('@/views/DocumentsView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, shouldPurchaseStatusBeNotDisabled],
	},
	{
		path: '/sale-invoice/documents',
		component: lazy(() => import('@/views/SaleDocumentsView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, shouldSaleStatusBeNotDisabled],
	},
	{
		path: '/issue',
		component: lazy(() => import('@/views/IssueDocumentsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseStatusBeNotDisabled,
			userShould(Permissions.ISSUE_DOCUMENTS),
		],
	},
	{
		path: '/sale-invoice/issue',
		component: lazy(() => import('@/views/IssueSaleDocumentsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldSaleStatusBeNotDisabled,
			userShould(Permissions.ISSUE_DOCUMENTS),
		],
	},
	{
		path: '/scheduleds',
		component: lazy(() => import('@/views/ScheduledsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseStatusBeNotDisabled,
			userShould(Permissions.EDIT_PROGRAMMED_DOCUMENTS),
		],
	},
	{
		path: '/sale-invoice/scheduleds',
		component: lazy(() => import('@/views/SaleScheduledsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldSaleStatusBeNotDisabled,
			userShould(Permissions.EDIT_PROGRAMMED_DOCUMENTS),
		],
	},
	{
		path: '/integrations',
		component: lazy(() => import('@/views/IntegrationsView')),
		beforeRoute: [
			shouldBeLoggedIn,
			shouldHaveActiveCompany,
			shouldPurchaseStatusBeNotDisabled,
			userShould(Permissions.EDIT_INTEGRATIONS),
		],
	},
	{
		path: '/suppliers',
		component: lazy(() => import('@/views/SuppliersView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
	},
	{
		path: '/clients',
		component: lazy(() => import('@/views/ClientsView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
	},
	{
		path: '/account',
		component: lazy(() => import('@/views/account-settings')),
		beforeRoute: [shouldBeLoggedIn],
	},
	{
		path: '/companies',
		component: lazy(() => import('@/views/CompaniesView')),
		beforeRoute: [shouldBeLoggedIn],
	},
	{
		path: '/referral-program',
		component: lazy(() => import('@/views/ReferralProgramView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany],
	},
	{
		path: '/companies/issuer',
		component: lazy(() => import('@/views/CompaniesIssuersView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, userShould(Permissions.COMPANY_ADMIN)],
	},
	{
		path: '/sign/:companyUid/:hash',
		component: lazy(() => import('@/views/MobileSignView')),
		layout: 'BlankLayout',
	},
	{
		path: '/plan',
		component: lazy(() => import('@/views/PlanView')),
		beforeRoute: [shouldBeLoggedIn, shouldHaveActiveCompany, userShould(Permissions.COMPANY_ADMIN)],
	},
]

