// ** React Imports
import { useState, useRef, useMemo } from 'react'

// ** Third Party Components
import { NavLink } from 'react-router-dom'

// icons
import { getDataByLanguage } from '@/utility/Utils'
import { Badge } from 'reactstrap'
import {
	Activity,
	Box,
	Briefcase,
	Calendar,
	ChevronDown,
	ChevronUp,
	FileText,
	List,
	LogOut,
	Plus,
	Share2,
	User,
} from 'react-feather'
import useAuthStore from '@/stores/auth-store'
import useCompaniesStore from '@/stores/companies-store'
import ActiveCompanySelector from './ActiveCompanySelector'
import usePermissionsStore, { Permissions } from '@/stores/permissions-store'
import './styles.scss'
import ReferralProgramCard from './ReferralProgramCard'
import MenuHeader from './MenuHeader'
import classNames from 'classnames'

const Sidebar = (props) => {
	const locale = useAuthStore((state) => state.locale)
	const activeCompany = useCompaniesStore((state) => state.activeCompany)
	const userCan = usePermissionsStore((state) => state.userCan)

	const [purchaseExpanded, setPurchaseExpanded] = useState(false)
	const [saleExpanded, setSaleExpanded] = useState(false)

	const saleDisabled = activeCompany?.issuer_sale_status == 'disabled'
	const purchaseDisabled = activeCompany?.issuer_purchase_status == 'disabled'
	const items = []
	if (activeCompany) {
		items.push(
			{
				title: { es: 'Inicio', en: 'Dashboard' },
				icon: Activity,
				navLink: '/dashboard',
			},
			{
				divider: true,
			},
			{
				header: 'EMISIÓN DE DOCUMENTOS',
			},
			{
				subheader: 'FACTURA DE COMPRA',
				expanded: purchaseExpanded,
				onClick: () => {
					setPurchaseExpanded(!purchaseExpanded)
				},
			},

			userCan(Permissions.ISSUE_DOCUMENTS) &&
				purchaseExpanded && {
					title: { es: 'Emisión Manual', en: 'Manual Issue' },
					icon: Plus,
					navLink: '/issue',
					disabled: purchaseDisabled,
				},

			userCan(Permissions.EDIT_PROGRAMMED_DOCUMENTS) &&
				purchaseExpanded && {
					title: { es: 'Emisión programada', en: 'Scheduled Invoices' },
					icon: Calendar,
					navLink: '/scheduleds',
					disabled: purchaseDisabled,
				},

			userCan(Permissions.EDIT_INTEGRATIONS) &&
				purchaseExpanded && {
					title: { es: 'Emisión por Integraciones', en: 'Integrations' },
					icon: Share2,
					navLink: '/integrations',
					disabled: purchaseDisabled,
				},

			purchaseExpanded && {
				title: { es: 'Documentos Emitidos', en: 'Invoices' },
				icon: FileText,
				navLink: '/documents',
				disabled: purchaseDisabled,
			},
			{
				divider: true,
				withPadding: true,
			},
			{
				subheader: 'FACTURA DE VENTA',
				expanded: saleExpanded,
				onClick: () => setSaleExpanded(!saleExpanded),
			},

			userCan(Permissions.ISSUE_DOCUMENTS) &&
				saleExpanded && {
					title: { es: 'Emisión Manual', en: 'Manual Issue' },
					icon: Plus,
					navLink: '/sale-invoice/issue',
					disabled: saleDisabled,
				},

			userCan(Permissions.EDIT_PROGRAMMED_DOCUMENTS) &&
				saleExpanded && {
					title: { es: 'Emisión programada', en: 'Scheduled Invoices' },
					icon: Calendar,
					navLink: '/sale-invoice/scheduleds',
					disabled: saleDisabled,
				},
			activeCompany &&
				saleExpanded && {
					title: { es: 'Documentos Emitidos', en: 'Invoices' },
					icon: FileText,
					navLink: '/sale-invoice/documents',
					disabled: saleDisabled,
				},
			{
				divider: true,
			}
		)
	}

	items.push(
		{
			header: 'CONFIGURACIÓN',
		},
		activeCompany && {
			title: { es: 'Listado de Proveedores', en: 'Suppliers' },
			icon: List,
			navLink: '/suppliers',
		},
		activeCompany && {
			title: { es: 'Listado de Clientes', en: 'Clients' },
			icon: List,
			navLink: '/clients',
		},
		{
			title: { es: 'Mi empresa', en: 'My company' },
			icon: Briefcase,
			navLink: '/companies',
		},
		userCan(Permissions.COMPANY_ADMIN) && {
			title: { es: 'Plan de Suscripción', en: 'My plan' },
			icon: Box,
			navLink: '/plan',
		},
		activeCompany && {
			custom: (key) => (
				<div style={{ padding: '24px' }} key={key}>
					<ReferralProgramCard />
				</div>
			),
		},
		{
			title: { es: 'Mi cuenta', en: 'Mi cuenta' },
			icon: User,
			navLink: '/account',
		},
		{
			title: { es: 'Cerrar sesión', en: 'Logout' },
			icon: LogOut,
			onClick: () => useAuthStore.getState().logout(),
		}
	)

	// ** Props
	const { menuCollapsed, routerProps, menu, currentActiveItem, skin } = props

	// ** States
	const [activeItem, setActiveItem] = useState(null)

	// ** Menu Hover State
	const [menuHover, setMenuHover] = useState(false)

	// ** Function to handle Mouse Enter
	const onMouseEnter = () => {
		if (menuCollapsed) {
			setMenuHover(true)
		}
	}

	return (
		<div
			className={classNames('main-menu menu-fixed menu-accordion menu-shadow', {
				expanded: menuHover || menuCollapsed === false,
				'menu-light': skin !== 'semi-dark' && skin !== 'dark',
				'menu-dark': skin === 'semi-dark' || skin === 'dark',
			})}
			onMouseEnter={onMouseEnter}
			onMouseLeave={() => setMenuHover(false)}
		>
			<MenuHeader menuHover={menuHover} {...props} />
			<ActiveCompanySelector />

			<ul className="navigation navigation-main">
				{items.filter(Boolean).map((item, i) => {
					if (item.divider) {
						return (
							<div className={classNames({ 'px-2': item.withPadding })} key={i}>
								<hr className="w-100" />
							</div>
						)
					} else if (item.header) {
						return (
							<li className="navigation-header" key={i}>
								<span>{item.header}</span>
							</li>
						)
					} else if (item.subheader) {
						return (
							<li
								className={classNames(`navigation-subheader`, {
									'navigation-subheader--expanded': item.expanded,
								})}
								key={i}
								onClick={() => item.onClick?.()}
							>
								<span className="d-flex align-items-center">
									{item.subheader}
									<div className="spacer"></div>
									{item.expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
								</span>
							</li>
						)
					} else if (item.custom) {
						return item.custom(i)
					} else {
						return (
							<li
								key={i}
								className={classNames({
									'nav-item': !item.children,
									disabled: item.disabled,
									active: item.navLink === activeItem,
								})}
							>
								<NavLink
									className="d-flex align-items-center"
									to={item.navLink || '#'}
									onClick={item.onClick}
									isActive={(match, location) => {
										if (!match) return false
										return match.url && match.url !== '' && match.url === item.navLink
									}}
								>
									<item.icon size={20} className="mr-1" />

									<span className="menu-item text-truncate">
										{getDataByLanguage(locale, item.title)}
									</span>

									{!!item.badge && !!item.badgeText && (
										<Badge className="ml-auto mr-1" color={item.badge} pill>
											{item.badgeText}
										</Badge>
									)}
								</NavLink>
							</li>
						)
					}
				})}
			</ul>
		</div>
	)
}

export default Sidebar

